import React from 'react';
import { get, noop } from 'lodash';
import { RawTimeRange, dateMath, dateTime } from '@grafana/data';
import { getTimeSrv } from '../features/dashboard/services/TimeSrv';

const normalizeTimerange = (timerange: RawTimeRange): RawTimeRange => {
  return {
    from: dateMath.isMathString(timerange.from) ? timerange.from : dateTime(timerange.from),
    to: dateMath.isMathString(timerange.to) ? timerange.to : dateTime(timerange.to),
  };
};

const setTime = (timerange: RawTimeRange): void => {
  getTimeSrv().setTime(timerange);
};

const setAutoRefresh = (interval: any): void => {
  getTimeSrv().setAutoRefresh(interval);
};

const listener = (event: any): void => {
  const key = get(event, 'data.key');
  const from = get(event, 'data.from');
  const to = get(event, 'data.to');
  const interval = get(event, 'data.interval');

  switch (key) {
    case 'GRAFANA_SET_TIMERANGE':
      return setTime(normalizeTimerange({ from, to }));
    case 'GRAFANA_SET_AUTO_REFRESH':
      return setAutoRefresh(interval);
    default:
      return noop();
  }
};

export class ParentListener extends React.Component<{ children: React.ReactNode }, any> {
  componentDidMount() {
    window.addEventListener('message', listener);
    console.warn('ECPS Listener was mounted');
  }
  componentWillUnmount() {
    window.removeEventListener('message', listener);
    console.warn('ECPS Listener was unmounted');
  }

  render() {
    return this.props.children;
  }
}
