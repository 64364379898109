import { RawTimeRange, rangeUtil, dateMath, isDateTime } from '@grafana/data';
import Moment from 'moment'; // eslint-disable-line no-restricted-imports

interface TimerangeString {
  from: number;
  to: number;
}

/*
  Функция приводит любой возможный тип (Moment, string, string вида now +- nx) к Unix Timestamp
*/
const normalizeTimerange = (timerange: RawTimeRange): TimerangeString => {
  const convertedRange = rangeUtil.convertRawToRange(timerange);

  const result = {
    from: Moment().valueOf(),
    to: Moment().valueOf(),
  };

  try {
    if (dateMath.isMathString(timerange.from)) {
      // Если у нас строка с математикой (now +- nx)
      result.from = convertedRange.from.valueOf();
    } else if (isDateTime(timerange.from)) {
      // Если у нас объект Moment
      result.from = timerange.from.valueOf();
    } else {
      // Если просто строка
      result.from = +timerange.from;
    }

    if (dateMath.isMathString(timerange.to)) {
      // Если у нас строка с математикой (now +- nx)
      result.to = convertedRange.to.valueOf();
    } else if (isDateTime(timerange.to)) {
      // Если у нас объект Moment
      result.to = timerange.to.valueOf();
    } else {
      // Если просто строка
      result.to = +timerange.to;
    }
  } catch (e) {
    console.error(e);
    return {
      from: Moment().valueOf(),
      to: Moment().valueOf(),
    };
  }

  return result;
};

export const notifyParent = (timerange: RawTimeRange): void => {
  if (parent) {
    const key = 'GRAFANA_TIMERANGE_CHANGED';
    const { from, to } = normalizeTimerange(timerange);

    if (from && to) {
      parent.postMessage(
        {
          key,
          from,
          to,
        },
        '*'
      );
    }
  }
};
